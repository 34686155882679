<template>
  <div>
    <b-container>
      <form-wizard ref="registerWizard" color="#e00825" :title="null" :subtitle="null" layout="vertical"
        finish-button-text="Conferma" back-button-text="Indietro" next-button-text="Avanti"
        class="vertical-steps steps-transparent mb-3">
        <template slot="step" slot-scope="props">
          <wizard-step :tab="props.tab" :transition="props.transition" :index="props.index">
          </wizard-step>
        </template>
        <tab-content title="Dati Personali" icon="feather icon-file-text" :before-change="customerDataValidation">
          <validation-observer ref="customerDataValidation">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Dati Personali</h5>
                <small class="text-muted">Inserisci i dati richiesti</small>
              </b-col>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="Nome" rules="required">
                  <b-form-group label="Nome" label-for="customer_name">
                    <b-form-input id="customer_name" v-model="customer_name"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="Cognome" rules="required">
                  <b-form-group label="Cognome" label-for="customer_surname">
                    <b-form-input v-model="customer_surname" id="customer_surname"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider #default="{ errors }" name="Data di nascita" rules="required">
                  <b-form-group label="Data di nascita" label-for="customer_birthdate">
                    <b-form-input type="date" v-model="customer_birthdate" id="customer_birthdate"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider #default="{ errors }" name="Luogo di nascita" rules="required">
                  <b-form-group label="Luogo di nascita" label-for="customer_birthcity">
                    <b-form-input v-model="customer_birthcity" id="customer_birthcity"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider #default="{ errors }" name="Codice fiscale" rules="required">
                  <b-form-group label="Codice fiscale" label-for="customer_taxcode">
                    <b-form-input v-model="customer_taxcode" id="customer_taxcode"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="Indirizzo" rules="required">
                  <b-form-group label="Indirizzo" label-for="customer_address">
                    <b-form-input v-model="customer_address" id="customer_address"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <b-row>
                  <b-col md="4">
                    <validation-provider #default="{ errors }" name="Città" rules="required">
                      <b-form-group label="Città" label-for="customer_city">
                        <b-form-input v-model="customer_city" id="customer_city"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="4">
                    <validation-provider #default="{ errors }" name="Provincia" rules="required">
                      <b-form-group label="Provincia" label-for="customer_province">
                        <b-form-select :items="provinces" v-model="customer_province"
                          :state="errors.length > 0 ? false : null">
                          <b-form-select-option value="" disabled>Seleziona...</b-form-select-option>
                          <b-form-select-option v-for="province in provinces" :key="province.geo_code"
                            :value="province.geo_code">{{ province.geo_name }}</b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="4">
                    <validation-provider #default="{ errors }" name="CAP" rules="required">
                      <b-form-group label="CAP" label-for="customer_zipcode">
                        <b-form-input v-model="customer_zipcode" id="customer_zipcode"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="Numero Telefonico" rules="required">
                  <b-form-group label="Numero Telefonico" label-for="customer_phone">
                    <b-input-group prepend="+ 39" append="">
                      <b-form-input v-model="customer_phone" type="tel" id="customer_phone"
                        :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <validation-provider #default="{ errors }" name="Indirizzo E-mail" rules="required|email">
                  <b-form-group label="Indirizzo E-mail" label-for="customer_email">
                    <b-form-input v-model="customer_email" type="email" id="customer_email"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row v-if="user.user.company_name == 'deco'">
              <b-col md="6">
                <validation-provider #default="{ errors }" name="Numero tessera fisica assegnata al cliente"
                  rules="required">
                  <b-form-group label="Numero tessera fisica assegnata al cliente" label-for="customer_cardnumber">
                    <b-form-input v-model="customer_cardnumber" type="text" id="customer_cardnumber"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <h3 v-if="user.user.company_name == 'deco'" class="pt-3">Consensi all'utilizzo dei dati personali</h3>
            <b-row v-if="user.user.company_name == 'deco'">
              <b-col md="6">
                <validation-provider #default="{ errors }" name="Finalità fidelizzazione" rules="required">
                  <b-form-group label="" label-for="consent_privacy">
                    <b-form-checkbox v-model="consent_privacy" id="consent_privacy"
                      :state="errors.length > 0 ? false : null">Finalità fidelizzazione (necessario al rilascio della
                      card)</b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <h3 v-if="user.user.company_name == 'deco'" class="pt-3">Manifestazione di consenso</h3>
            <p v-if="user.user.company_name == 'deco'">
              Il Titolare preso atto di quanto riportato nell'<a target="_blank" href="https://bit.ly/3yG3sqN">informativa</a>, relativamente al trattamento dei propri dati
              personali per le finalità funzionali alle attività di seguito riportate:</p>
            <b-row v-if="user.user.company_name == 'deco'">
              <b-col md="4">
                <validation-provider #default="{ errors }"
                  name="Invio di newsletter e materiale pubblicitario promozionale" rules="required">
                  <b-form-group label="Invio di newsletter e materiale pubblicitario promozionale" label-for="consent_communications">
                    <b-form-radio-group>
                      <b-form-radio v-model="consent_communications" name="consent_communications" value="Y"
                        :state="errors.length > 0 ? false : null">Autorizzo</b-form-radio>
                      <b-form-radio v-model="consent_communications" name="consent_communications" value="N"
                        :state="errors.length > 0 ? false : null">Non autorizzo</b-form-radio>
                    </b-form-radio-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider #default="{ errors }" name="Lettura delle preferenze d'acquisto" rules="required">
                  <b-form-group label="Lettura delle preferenze d'acquisto" label-for="consent_profilation">
                    <b-form-radio-group>
                      <b-form-radio v-model="consent_profilation" name="consent_profilation" value="Y"
                        :state="errors.length > 0 ? false : null">Autorizzo</b-form-radio>
                      <b-form-radio v-model="consent_profilation" name="consent_profilation" value="N"
                        :state="errors.length > 0 ? false : null">Non autorizzo</b-form-radio>
                    </b-form-radio-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider #default="{ errors }" name="Ricerche di mercato sui prodotti e servizi Decò"
                  rules="required">
                  <b-form-group label="Ricerche di mercato sui prodotti e servizi Decò" label-for="consent_research">
                    <b-form-radio-group>
                      <b-form-radio v-model="consent_research" name="consent_research" value="Y"
                        :state="errors.length > 0 ? false : null">Autorizzo</b-form-radio>
                      <b-form-radio v-model="consent_research" name="consent_research" value="N"
                        :state="errors.length > 0 ? false : null">Non autorizzo</b-form-radio>
                    </b-form-radio-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <tab-content title="OTP" icon="feather icon-user">
          <b-row>
            <b-col md="4"></b-col>

            <b-col md="4">
              <div class="d-flex flex-column justify-content-center align-items-center h-100">
                <div>
                  <h3>Verifica OTP</h3>
                  <p>
                    È stato inviato un codice numerico sul tuo telefono,
                    inseriscilo nei campi sotto.
                  </p>

                  <p>
                    <b>{{ customer_phone }}</b>
                  </p>
                </div>

                <div class="text-center">
                  <p>
                    <strong>Scrivi il codice di sicurezza a 5 cifre</strong>
                  </p>
                  <v-otp-input ref="otpInput" input-classes="otp-input" separator :is-input-num="true"
                    :should-auto-focus="true" :num-inputs="5" @on-complete="handleOnComplete" />

                  <b-button class="my-2" block variant="primary" @click="verifyOtp">Conferma</b-button>
                </div>

                <p>
                  Non hai ricevuto il codice?
                  <a href="#" class="text-primary" @click="customerRegister(true)">Reinvia</a>
                </p>
              </div>
            </b-col>

            <b-col md="4"></b-col>
          </b-row>
        </tab-content>

        <tab-content title="Riepilogo" icon="feather icon-map-pin">
          <div class="d-flex flex-column align-items-center justify-content-center h-100">
            <div>
              <feather-icon style="transform: translate(-8px, 0px)" icon="CheckSquareIcon" class="text-primary"
                size="100" />
              <h4 class="my-1">Perfetto</h4>
              <p>
                Tutti i dettagli sono stati inviati tramite email al cliente
              </p>
              <div class="mt-5">
                <b-button class="mr-2" variant="primary" @click="resetWizard">
                  Registra un nuovo utente
                </b-button>
                <b-button variant="secondary" @click="$router.push('/dashboard')">
                  Torna alla Dashboard
                </b-button>
              </div>
            </div>
          </div>
        </tab-content>

        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-left"></div>
          <div class="wizard-footer-right">
            <b-button variant="primary" v-if="!props.isLastStep && props.activeTabIndex != 1"
              @click="$refs.registerWizard.nextTab()" class="wizard-footer-right">Avanti</b-button>
          </div>
        </template>
      </form-wizard>
    </b-container>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";
import * as provinces from "@/assets/data/province.json";

import vSelect from "vue-select";
import VOtpInput from "@bachdgvn/vue-otp-input";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { required, email } from "@validations";

import { Requests } from "@/api/requests.js";
import { mapState } from "vuex";
import {
  BButton,
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BInputGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    FormWizard,
    TabContent,
    BButton,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BFormSelectOption,
    vSelect,
    WizardStep,
    // eslint-disable-next-line vue/no-unused-components
    VOtpInput,
  },
  data: () => ({
    customer_name: "",
    customer_surname: "",
    customer_birthdate: "",
    customer_birthcity: "",
    customer_taxcode: "",
    customer_address: "",
    customer_city: "",
    customer_province: "",
    customer_zipcode: "",
    customer_phone: "",
    customer_email: "",
    customer_id: "",
    otp_code: "",
    customer_cardnumber: null,
    consent_privacy: "",
    consent_communications: "",
    consent_profilation: "",
    consent_research: "",
    provinces: provinces.default,
  }),

  computed: {
    ...mapState(["user"]),
  },

  created() {
    localize("it");
  },

  methods: {
    customerDataValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.customerDataValidation.validate().then((success) => {
          if (success) {

            resolve(this.customerRegister());

          } else {
            reject();
          }
        });
      });
    },

    async customerRegister(resend = false) {
      let data = new FormData();

      data.append("customer_name", this.customer_name);
      data.append("customer_surname", this.customer_surname);
      data.append("customer_birthdate", this.customer_birthdate);
      data.append("customer_birthcity", this.customer_birthcity);
      data.append("customer_taxcode", this.customer_taxcode);
      data.append("customer_address", this.customer_address);
      data.append("customer_city", this.customer_city);
      data.append("customer_province", this.customer_province);
      data.append("customer_zipcode", this.customer_zipcode);
      data.append("customer_phone", this.customer_phone);
      data.append("customer_email", this.customer_email);
      if (this.customer_cardnumber) {
        data.append("customer_cardnumber", this.customer_cardnumber);
      }
      data.append("company_name", this.user.user.company_name);
      data.append("consent_communications", this.consent_communications);
      data.append("consent_profilation", this.consent_profilation);
      data.append("consent_research", this.consent_research);
      data.append("id_store", this.user.user.id_store);

      try {
        const response = await Requests.customerRegister(data);

        switch (response.customer_addnew.success) {

          case "true": {

            this.customer_id = response.customer_addnew.customer_id;
            if (resend) {
              this.$swal.fire({
                icon: "success",
                title: "Ok!",
                text: "Abbiamo mandato un nuovo codice al numero di telefono specificato",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
            return true;
          }

          case "false": {
            this.$swal.fire({
              icon: "error",
              title: "Errore",
              text: response.customer_addnew.feedback,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            return false;
          }

        }
      } catch (err) {
        console.log(err);
      }
    },

    async verifyOtp() {
      let data = new FormData();

      data.append("customer_id", this.customer_id);
      data.append("otp_code", this.otp_code);

      try {
        const response = await Requests.verifyOtp(data);
        switch (response.otp_verify.success) {
          case "true": {
            this.$refs.registerWizard.nextTab();
            break;
          }

          case "false": {
            this.$swal.fire({
              icon: "error",
              title: "Errore",
              text: response.error,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            break;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    resetWizard() {

      this.$refs.customerDataValidation.reset();
      this.$refs.otpInput.clearInput();

      this.customer_name = "";
      this.customer_surname = "";
      this.customer_birthdate = "";
      this.customer_birthcity = "";
      this.customer_taxcode = "";
      this.customer_address = "";
      this.customer_city = "";
      this.customer_province = "";
      this.customer_zipcode = "";
      this.customer_phone = "";
      this.customer_email = "";
      this.customer_id = "";
      this.otp_code = "";
      this.customer_cardnumber = null;
      this.consent_communications = "N";
      this.consent_profilation = "N";
      this.consent_research = "N";

      this.$refs.registerWizard.reset()

    },

    handleOnComplete(value) {
      this.otp_code = value;
    },

  },
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
